import { IUserApi } from '../../../user/types';

declare global {
  interface Window {
    ChurnZero: any;
  }
}

const CHURN_ZERO_APP_KEY = '1!HFQiLR65vhYzqy-XCTDxlWGNiP3xvpgbjnwYeecbAeIt576';

export const initWebAnalytics = (currentUser: IUserApi) => {
  const { NODE_ENV } = process.env;

  window.ChurnZero = window.ChurnZero || null;

  if (window.ChurnZero && NODE_ENV === 'production') {
    window.ChurnZero.push(['setAppKey', CHURN_ZERO_APP_KEY]);
    window.ChurnZero.push([
      'setContact',
      currentUser.defaultCompany,
      currentUser.email,
      currentUser?.givenName,
      currentUser?.familyName,
    ]);
  }
};
